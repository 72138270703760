<template>
    <v-container class="panel-wrapper  pa-0 guidance-wrapper" grid-list-lg fluid>
        <tab-card title="Guidance">
            <v-layout column v-if="guidance">
                <v-flex v-for="(v,k) in guidance" :key="k">
                    <v-subheader class="panel-title">{{k+1}}. {{v.category}}</v-subheader>
                    <v-layout class="map-content" column>
                        <v-flex class="content-wrapper"  v-for="item in v.content"
                                :key="item.title">
                            <i class="fa fa-circle"></i><a class="py-2" target="_blank"
                                                           :href="item.url">{{item.title}} </a>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div v-else class="my-3" style="height: 60vh">
                <Spin></Spin>
            </div>
        </tab-card>
    </v-container>
</template>

<script>
    import TabCard from '@/components/TabCard'
    import {mapState} from 'vuex'
    import Spin from "@/components/Spin";

    export default {
        name: "index",
        components: {Spin, TabCard},
        data() {
          return {
              guidance:null,
          }
        },
        computed: {
            ...mapState('application', ['appInfo'])
        },
        created() {
            this.$SDK.track({pageName:'Guidance',productName:this.appInfo.product_name})
            this.$http.get(`/content-blocks/?q=name__in=guidance_${this.appInfo.product_name.toLowerCase().replace(' ', '')},websites__name=Client Panel`).then((res) => {
                this.guidance = res.results[0].content_items[0].detail.table_of_content
            }).catch(e =>{
                this.$message.error(e.detail)
            })
        }
    }
</script>

<style lang="scss">

    .guidance-wrapper {
        .map-content {
            padding-left: 20px;

            .content-wrapper {
                a {
                    color: #666666;
                    text-decoration: none;
                    &:hover {
                        color: #3F51B5;
                        text-decoration: underline;
                    }
                }

                i {
                    color: #3F51B5;
                    margin-right: 8px;
                }
            }
        }

    }
</style>