var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper  pa-0 guidance-wrapper",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "tab-card",
        { attrs: { title: "Guidance" } },
        [
          _vm.guidance
            ? _c(
                "v-layout",
                { attrs: { column: "" } },
                _vm._l(_vm.guidance, function(v, k) {
                  return _c(
                    "v-flex",
                    { key: k },
                    [
                      _c("v-subheader", { staticClass: "panel-title" }, [
                        _vm._v(_vm._s(k + 1) + ". " + _vm._s(v.category))
                      ]),
                      _c(
                        "v-layout",
                        { staticClass: "map-content", attrs: { column: "" } },
                        _vm._l(v.content, function(item) {
                          return _c(
                            "v-flex",
                            { key: item.title, staticClass: "content-wrapper" },
                            [
                              _c("i", { staticClass: "fa fa-circle" }),
                              _c(
                                "a",
                                {
                                  staticClass: "py-2",
                                  attrs: { target: "_blank", href: item.url }
                                },
                                [_vm._v(_vm._s(item.title) + " ")]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "my-3", staticStyle: { height: "60vh" } },
                [_c("Spin")],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }